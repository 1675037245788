<template>
    <div>
        <div class="modal fade" id="PaymentInvoiceCreateModal" tabindex="-1" aria-labelledby="PaymentInvoiceCreateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="PaymentInvoiceCreateModalLabel" class="font-weight-bold">Add Payment Invoice</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <payment-invoice-create-grid ref="grid" :key="gridReload" :createClick="createClick"/>
                        <br>
                        <CButton type="button" @click="createClick()" id="saveButton" color="success" style="float: right;"> Create </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>    
import paymentInvoiceCreateGrid from '../Grid/PaymentInvoiceCreateGrid.vue';
import paymentInvoiceServices from '../Script/PaymentInvoiceServices';

export default {
    name: 'PaymentInvoiceCreateForm',
    components: {
        'payment-invoice-create-grid': paymentInvoiceCreateGrid,
    },
    mounted:  function () {
    },
    data: function () {
        return {
           gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.reload();
            window.$('#PaymentInvoiceCreateModal').modal('show');
        },
        createClick(){
            if(this.$refs.grid.hf_ID_Filter == ''){
                this.$swal("Info", "Harap untuk memilih invoice sebelum menekan tombol Create", "info");
            }else{
                var validate = paymentInvoiceServices.validateDuplicateSelectedData(this.$refs.grid.hf_ID_Filter);
                
                if(validate == 'not match'){
                    this.$swal("Info", "Harap untuk memilih invoice dengan customer dan tipe invoice yang sama", "info");
                }else{;
                    var selectedId = JSON.stringify(validate);
                    window.$('#PaymentInvoiceCreateModal').modal('hide');
                    this.$router.push({ name: 'Payment Invoice Form', params: { formtype:'Add', id:selectedId, view:'new' } });
                }
            }
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>
<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>