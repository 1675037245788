<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:databound="dataBound"
            :resizable="true"
            >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import paymentInvoiceServices from '../Script/PaymentInvoiceServices';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PaymentnvoiceCreateGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['createClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                    page: 1,
                    pageSize: kendo_grid.default_form_grid_pagesize,
                    serverPaging: false,
                    serverFiltering: false,
                    serverSorting: false,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                return { query: paymentInvoiceServices.readPayableInvoiceQuery()};
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if(response.data.GetTransactionPayableInvoiceList == null){
                                return [];
                            }else{
                                return response.data.GetTransactionPayableInvoiceList;
                            }
                        },
                        total: function (response) {
                            if(response.data.GetTransactionPayableInvoiceList == null){
                                return 0;
                            }else{
                                return response.data.GetTransactionPayableInvoiceList.length;
                            }
                        },
                        model: {
                            fields: {
                                invoice_type: {type: "dropdown" },
                                invoice_date: {type: "date"},
                                left: {type: "number"},
                            }
                        }
                    },
                    sort: { field: "invoice_date", dir: "desc" }
                }),
            columns:  [
                { template:this.columnTemplate, headerTemplate: this.headerTemplate, width: 40, headerAttributes:{ style: "text-align:center;" }, attributes:{style: "text-align:center;"} },
                { field: "invoice_type", title: "Tipe Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" }, attributes: { "class": "k-text-center" },
                    filterable: { ui: this.invoiceTypeFilter },
                    template: "#= invoice_type == 1 ? 'Invoice' : 'Deposit' #" },
                { field: "invoice_number", title: "Nomor Bukti", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "invoice_date", title: "Tanggal", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    template: "#= kendo.toString(kendo.parseDate(invoice_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "purchase_order.purchase_order_number", title: "Nomor PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "customer_name", title: "Nama Customer", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "purchase_order.sp_number", title: "Nomor SP", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "left", title: "Nominal", width: 200, format: "{0:N}", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "purchase_order.payment_method", title: "Cara Bayar", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" }, attributes: { "class": "k-text-center" } },
                { field: "invoice_date", title: "Jatuh Tempo", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" }, 
                    template: "#= kendo.toString(kendo.date.addDays(kendo.parseDate(invoice_date, 'yyyy-MM-dd'), kendo.parseInt(purchase_order.term_of_payment)), 'yyyy-MM-dd') #"
                },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
            hf_CheckAll_Filter:"false",
            hf_ID_Filter:"",
            invoiceTypeFilterData: [
                {value: 1, label: 'Invoice'},
                {value: 2, label: 'Deposit'}
            ],
        };
    },
    mounted: async function() {
        var vueComponent = this;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("change", "#masterCheckBoxFilter", function (e) {
            e.preventDefault();
            var state= this.checked;
            var data = grid._data;
            
            for (var i = 0; i < data.length; i++) {
                var datarow = data[i]
                var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                
                if (elementRow != null) {
                    if (state) {
                    elementRow.checked = true;
                    grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                    }
                    else
                    {
                    elementRow.checked = false;
                    grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                    }
                }
             }
             var hf_CheckAll_Filter = vueComponent.hf_CheckAll_Filter;
              if (state && hf_CheckAll_Filter == "true") {
                    vueComponent.hf_CheckAll_Filter="true";
                }
                else if (!state && hf_CheckAll_Filter == "true") {
                    vueComponent.hf_CheckAll_Filter="false";
                    vueComponent.hf_ID_Filter="";
                }
                else if (state && hf_CheckAll_Filter == "false") {
                    vueComponent.hf_CheckAll_Filter= "true";
                    vueComponent.hf_ID_Filter="";
                }
                else if (!state)
                    vueComponent.hf_CheckAll_Filter="false";
                else
                    vueComponent.hf_CheckAll_Filter= "true";
        });

        GridElement.on("change", ".cbFilter", function (e) {
            e.preventDefault();
            var checked = this.checked;
            var rowElement = grid.tbody.find(e.target.closest("tr"));
            var row = grid.dataItem(e.target.closest("tr"));
            var hfIDValue = vueComponent.hf_ID_Filter;
            var uid = row.uid;
            var dataItem = row.invoice_id.toString() + '|' + row.customer_id + '|' + row.invoice_type.toString();
                       
            var checkedIdArray = hfIDValue.split(',');

            var lastvalueHfid =checkedIdArray.slice(-1)[0];
            var hf_CheckAll_Filter = vueComponent.hf_CheckAll_Filter;
           
            if (checked)
                grid.tbody.find("tr[data-uid='" +uid+ "']").addClass("k-state-selected");
            else
                grid.tbody.find("tr[data-uid='" +uid+ "']").removeClass("k-state-selected");
            
            var lastIndex = (hfIDValue.length - dataItem.length)
            if (!checked && hf_CheckAll_Filter == "true") {
            if (hfIDValue == "")
               hfIDValue = dataItem;
                else {
                    //Check if not exist
                    if (hfIDValue.indexOf(dataItem) == "-1")
                        hfIDValue = hfIDValue + "," + dataItem;
                }
             }
            else if (!checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {    
                if(lastvalueHfid != dataItem.toString())
                {
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                }
                else if (lastvalueHfid == dataItem.toString()) 
                {
                    hfIDValue = hfIDValue.replace("," + dataItem, '');  
                }
                else
                {
                    hfIDValue = hfIDValue.replace(dataItem, '');
                }
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace("," + dataItem, '');   
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "true") {
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace("," + dataItem, '');
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {
                if (hfIDValue == "")
                    hfIDValue = dataItem;
                else {
                    if(!checkedIdArray.includes(dataItem))
                    {
                        hfIDValue = hfIDValue + "," + dataItem;
                    }
                }
            }
            if (checked) {
                rowElement.addClass("k-state-selected");
            } else {
                rowElement.removeClass("k-state-selected");
            }
            vueComponent.hf_ID_Filter = hfIDValue
        });
    },
    methods: {
        invoiceTypeFilter: function(e) {
            e.kendoDropDownList({
                autoBind: false,
                dataSource: this.invoiceTypeFilterData,
                dataTextField: "label",
                dataValueField: "value",
                optionLabel: "Pilih Tipe Invoice"
            });
        },
        headerTemplate() {
            return `&nbsp;`
        },
        columnTemplate() {
            return `<input type="checkbox" class="cbFilter" />`
        },
        dataBound: function (e) {
            var grid = this.$refs.grid.kendoWidget();
            var checked = $("#masterCheckBoxFilter").is(':checked');
            var data = grid._data;
            var splitIdArray =  this.hf_ID_Filter.split(',');
            
            if (checked) {
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                    var dataItem = datarow.invoice_id.toString() + '|' + datarow.customer_id + '|' + datarow.invoice_type.toString();

                    if (elementRow != null) {
                        if (checked && splitIdArray.indexOf(dataItem) == -1) {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                        else
                        {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                    }
                }
            }else{
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                    var dataItem = datarow.invoice_id.toString() + '|' + datarow.customer_id + '|' + datarow.invoice_type.toString();

                    if (elementRow != null) {
                        if (splitIdArray.indexOf(dataItem) == -1) {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                        else 
                        {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                    }
                }
            }
        },
    }
}
</script>
<style scoped>
</style>
