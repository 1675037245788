<template>
    <div>
        <input hidden type="text" class="form-control" id="PaymentInvoiceId" v-model="PaymentInvoiceId">
        <div class="modal fade" id="PaymentInvoiceStatusModal" tabindex="-1" aria-labelledby="PaymentInvoiceStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="PaymentInvoiceStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{PaymentInvoiceNo}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                     
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import moment from 'moment';
import {globalfunc} from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import paymentInvoiceServices from '../Script/PaymentInvoiceServices.js';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PaymentInvoiceStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            PaymentInvoiceId : '',
            PaymentInvoiceNo : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(invData){
            this.ModalTitle = 'Edit Status Payment PaymentInvoice: ';
            this.PaymentInvoiceId = invData.inv_payment_id;
            this.PaymentInvoiceNo = invData.inv_payment_no;
            
            this.StatusData = await globalfunc.globalDropdown('ddl_status_invoice_payment');
            this.Status = invData.status;
            
            window.$('#PaymentInvoiceStatusModal').modal('show');
        },
        async saveClick(){
            var data = await paymentInvoiceServices.getPayableInvoiceDetail(this.PaymentInvoiceId);

            var gridItem = data.invoice_payment_detail;
            var invoicePaymentDetail = this.$globalfunc.objectToArrayConverter(gridItem, "Transaction-PaymentInvoice-InvoicePaymentDetail");

            const paymentInvoiceData = {
                inv_payment_type: data.inv_payment_type,
                status: this.Status,
                customer_id: data.customer_id,
                inv_payment_date: moment(data.inv_payment_date).format("YYYY-MM-DD"),
                payment_method: data.payment_method,
                bank: data.bank,
                branch: data.branch,
                account_number: data.account_number,
                account_name: data.account_name,
                notes: data.notes,
                round_up: parseFloat(data.round_up),
                delivery_fee: parseFloat(data.delivery_fee),
                admin_fee: parseFloat(data.admin_fee),
                other_fee: parseFloat(data.other_fee),
                transaction_invoice_payment_detail: invoicePaymentDetail
            }

            const variables = {
                id : parseInt(this.PaymentInvoiceId),
                data : paymentInvoiceData
            }
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                    paymentInvoiceServices.editQuery(variables).then(res => {
                        window.$('#PaymentInvoiceStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(error => {
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>