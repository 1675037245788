import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class PaymentInvoiceServices {
  readPaymentInvoiceQuery(){
      var query = `query($paging:ServerPaging, $status:String){
        GetTransactionInvoicePayment(Paging:$paging, Status:$status){
          transaction_invoice_payment{
            inv_payment_id
            inv_payment_number
            inv_payment_date
            inv_payment_type
            customer_name
            payment_method
            bank
            account_name
            notes
            status
            total
            invoice_numbers
          }
          total
        }
      }`;
      return query;
  }

  readPayableInvoiceQuery(){
      var query = `query{
        GetTransactionPayableInvoiceList{
            invoice_type
            invoice_id
            invoice_number
            invoice_date
            purchase_order_id
            customer_id
            customer_name
            notes
            total
            paid
            total_payment
            left
            purchase_order{
              purchase_order_number
              sp_number
              term_of_payment
              payment_method
            }
          }
      }`;
      return query;
  }

  async getPayableInvoiceList(id){
    var array = JSON.parse(id)
    
    const variables = {
        id : array
    }
    var query = gql`query($id:[Int]){
      GetTransactionPayableInvoiceList(InvoiceIds: $id){
        invoice_type
        invoice_id
        invoice_number
        tax_invoice
        customer_id
        customer_name
        notes
        total
        paid
        total_payment
        left
      }
    }`;
    var result = await globalfunc.defaultApolloQueryDefault(query, variables);

    return result.data.GetTransactionPayableInvoiceList;
  }

  async getPayableInvoiceDetail(id){
    const variables = {
      id : id
    }
    var query = gql`query($id:Int){
      GetTransactionInvoicePayment(InvoicePaymentID:$id){
        transaction_invoice_payment {
          created_at
          last_update
          inv_payment_id
          inv_payment_number
          inv_payment_type
          inv_payment_date
          status
          customer_id
          customer_name
          payment_method
          bank
          branch
          account_number
          account_name
          notes
          round_up
          delivery_fee
          admin_fee
          other_fee
          invoice_numbers
          invoice_payment_detail {
            created_at
            last_update
            inv_payment_id
            invoice_id
            invoice_number
            tax_invoice
            total
            paid
            total_payment
            left
            notes
          }
        }
      }
    }`;
    var result = await globalfunc.defaultApolloQueryDefault(query, variables);

    return result.data.GetTransactionInvoicePayment.transaction_invoice_payment[0];
  }

  async getBankQuery(){
    var query = gql`query{
      GetBankList{
        id
        bank_name
      }
    }`;
    var result = await globalfunc.defaultApolloQueryDefault(query);
    var data = [];
    for (let i = 0; i < result.data.GetBankList.length; i++) {
        var str = { value: result.data.GetBankList[i].bank_name, 
                    label: result.data.GetBankList[i].bank_name}
        data.push(str);
    }

    return data;
  }

  paymentGridPaymentInvoice(data, olddata){
    var result = []; 
    if(data != null){
      for (let i = 0; i < data.length; i++) {
        if(olddata[i].paid > 0) {
          var str = { 
            invoice_id: data[i].invoice_id,
            invoice_number: data[i].invoice_number,
            tax_invoice: data[i].tax_invoice,
            notes: data[i].notes,
            total: Math.round(data[i].total),
            paid: Math.round(olddata[i].paid + data[i].left),
            left: Math.round(data[i].left),
            remaining: Math.round(parseFloat(data[i].total-olddata[i].paid)-data[i].left)
          }
          result.push(str)
        }
        else {
          var str = { 
            invoice_id: data[i].invoice_id,
            invoice_number: data[i].invoice_number,
            tax_invoice: data[i].tax_invoice,
            notes: data[i].notes,
            total: Math.round(data[i].total),
            paid: Math.round(data[i].left),
            left: Math.round(data[i].left),
            remaining: Math.round(parseFloat(data[i].total).toFixed(2)-data[i].left)
          }
          result.push(str)
        }
      }
    }
    return result;
  }

  paymentGridPaymentInvoiceEdit(data){
    var result = []; 
    if(data != null){
      for (let i = 0; i < data.length; i++) {
        var str = { invoice_id: data[i].invoice_id,
                    invoice_number: data[i].invoice_number,
                    tax_invoice: data[i].tax_invoice,
                    notes: data[i].notes,
                    total: Math.round(data[i].total),
                    paid: Math.round(data[i].paid),
                    left: Math.round(data[i].total_payment),
                    remaining: Math.round(parseFloat(data[i].total - data[i].paid).toFixed(2))
                  }
        result.push(str)
      }
    }
    return result;
  }

  validateDuplicateSelectedData(data){
    var array = data.split(',');

    let arrayObj = [];
    for (let i = 0; i < array.length; i++) {
      var detailArray = array[i].split('|');
      var obj = {id: detailArray[0],
                customer_id : detailArray[1],
                invoice_type : detailArray[2],}
      arrayObj.push(obj)
    }
    
    var unique = arrayObj
     .map(e => e['customer_id'] + '|' + e['invoice_type'])
     .map((e, i, final) => final.indexOf(e) === i && i)
     .filter(obj=> arrayObj[obj]);

    if(unique.length > 1){
      return "not match";
    }else{
      var id = arrayObj.map(e => e['id']);
      return id;
    }
  }

  async addQuery(variables){
    var query = gql`mutation($data:NewTransactionInvoicePayment!){
      CreateTransactionInvoicePayment(New:$data)
    }`;
      
    return await globalfunc.defaultApolloMutationDefault(query, variables)
  }

  async editQuery(variables){
    var query = gql`mutation($id:Int!, $data:NewTransactionInvoicePayment!){
      UpdateTransactionInvoicePayment(InvoicePaymentId:$id, New:$data)
    }`;
      
    return await globalfunc.defaultApolloMutationDefault(query, variables)
  }
}

export default new PaymentInvoiceServices();
